import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import classnames from 'classnames';
import qs from 'query-string';
import moment from 'moment';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { LoggedInUserMenu, UnauthorizeRoutes } from './constants/UserMenu';
import PrivateRoute from './components/PrivateRoute';
import Sidebar from './components/menu/Sidebar';
import Topbar from './components/menu/Topbar';
import NotFound from './pages/NotFound';
import ModalContainer from './components/modals/ModalContainer';
import { isPermitted } from './helpers/permission';
import i18n from './i18n';
import { getAllSettings } from './store/thunks/settings.thunks';
import events from './helpers/events';
import Loader from './components/Loader';
import { log } from './helpers/utils';
import { TOKEN_REFRESH_RATE, setDataInLocalStorage } from './helpers/auth';
import { refreshToken, logout } from './store/thunks/auth.thunks';
import { getUser } from './store/thunks/user.thunks';
import { selectUser } from './store/selectors/user.selector';
import { selectMenu } from './store/selectors/settings.selector';
import { selectIsTokenExpires, selectIsTokenRefreshing } from './store/selectors/auth.selector';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from './store';
import { getClient, getClientSettings } from './store/thunks/client.thunk';
import { getCandidateDetails } from './store/thunks/candidates.thunks';
import { selectClient, selectClientConfig } from './store/selectors/client.selector';
import { PermissionLevel } from './constants/User';
import { useSelectClient } from './hooks/useSelectClient';
import { useDebouncedEffect } from './hooks/useDebouncedEffect';
import { getPosition, getPipelineSteps } from './store/api/positions.api';
import { setActivePosition, setFiltersWithKey, setCandidatesWithKey } from './store/slices/settings.slice';
import { toggleSplash } from './helpers/toggle-splash';
import { setTokens } from './store/slices/auth.slice';

const tokenExpiration = 'ts-access-token-expiration';
const selectClientPath = '/select-client';



export enum LinkRedirectScope {
  Candidate = 0,
  Position = 1,
}

let tokenTimer: ReturnType<typeof setTimeout> | null;
const runGlobalTokenChecker = () => {
  if (
    !localStorage.getItem('ts-api-token') ||
    !localStorage.getItem('ts-refresh-token') ||
    !localStorage.getItem(tokenExpiration)
  ) {
    return;
  }

  const tokenRefreshTimeout = moment(localStorage.getItem(tokenExpiration)).diff(moment());

  tokenTimer = setTimeout(
    async () => {
      await dispatchToolkit(refreshToken());
      // push recurrsion down the call stack
      setTimeout(() => runGlobalTokenChecker(), 0);
    },
    tokenRefreshTimeout > 0 ? tokenRefreshTimeout : TOKEN_REFRESH_RATE * 1000
  );
};

const stopGlobalTokenChecker = () => {
  if (tokenTimer) {
    clearTimeout(tokenTimer);
  }
};

const setPositionAndPipelinesStepsBeforeRedirectToCandidate = async (positionId: string, pipelineStepId: string): Promise<void> => {
  const position = await getPosition(positionId);
  const pipelineSteps = await getPipelineSteps(positionId, {
    filters: `positionId==${positionId}`,
  });
  dispatchToolkit(
    setActivePosition({
      ...position,
      pipelines: pipelineSteps || [],
    })
  );

  dispatchToolkit(
    setFiltersWithKey({
      key: 'candidates',
      payload: {
        positionId,
        status: 'Active',
        rejected: false,
        pipelineStepId,
      },
    })
  );
};

const setCandidateViewData = (candidateId: string, candidateSettings: any) => {
  dispatchToolkit(getCandidateDetails(candidateId));
  const activeCandidate = candidateSettings.active;

  dispatchToolkit(
    setCandidatesWithKey({
      key: 'fromRedirect',
      payload: true,
    })
  );

  dispatchToolkit(
    setCandidatesWithKey({
      key: 'active',
      payload: activeCandidate,
    })
  );

  dispatchToolkit(
    setCandidatesWithKey({
      key: 'selected',
      payload: [activeCandidate],
    })
  );

  dispatchToolkit(
    setCandidatesWithKey({
      key: 'unchecked',
      payload: [],
    })
  );

  dispatchToolkit(
    setCandidatesWithKey({
      key: 'selectAll',
      payload: false,
    })
  );
};

const routes = () => {
  const { boot, shutdown } = useIntercom();
  const { t } = useTranslation();
  const currentUser = useSelectorToolkit(selectUser);
  const isTokenExpired = useSelectorToolkit(selectIsTokenExpires);
  const isTokenRefreshing = useSelectorToolkit(selectIsTokenRefreshing);
  const activeClient = useSelectorToolkit(selectClient);
  const clientConfig = useSelectorToolkit(selectClientConfig);
  const { minimized } = useSelectorToolkit(selectMenu);
  const isSettingsLoading = useSelectorToolkit((state) => state.settings.loading);
  const candidatesSettings = useSelectorToolkit(({ settings }) => settings.candidates);
  const language = currentUser.locale || currentUser.language;
  const location = useLocation();
  const history = useHistory();
  const { selectClientById } = useSelectClient();
  const search = qs.parse(window.location.search);
  const shouldTopbarHidden = useMemo(() => {
    const { pathname } = location;
    if (pathname) {
      return pathname.startsWith('/career-page') || pathname.startsWith(selectClientPath);
    }

    return false;
  }, [location]);
  const activeClientConfig = useMemo(() => ({ ...activeClient, ...clientConfig }), [activeClient, clientConfig]);

  useDebouncedEffect(
    () => {
      (async () => {
        if (search.redirect) {
          const decoded = atob(search.redirect as string);

          if (currentUser.authenticated) {
            try {
              const decodedObject = JSON.parse(decoded);
              switch (decodedObject.redirect) {
                case LinkRedirectScope.Candidate: {
                  const clientId = await selectClientById(decodedObject.model.clientId, false);
                  if (clientId) {
                    await setPositionAndPipelinesStepsBeforeRedirectToCandidate(decodedObject.model.positionId, decodedObject.model.pipelineStepId);
                    await setCandidateViewData(decodedObject.model.candidateId, candidatesSettings);
                    // Random timeout to avoid flickering
                    history.push(`/candidates/view/${decodedObject.model.candidateId}${decodedObject?.additionalParams}`);
                  }
                  break;
                }
                default:
                  break;
              }
            } catch (error) {
              log(error);
              NotificationManager.error(t('admin.client.no-access'));
              history.push('/');
            }
          }
        }
      })();
    },
    [search, currentUser],
    1000
  );

  useEffect(() => {
    if (currentUser.authenticated) {
      i18n.changeLanguage(language);
    } else {
      i18n.changeLanguage(window.navigator.language);
    }
  }, [language]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Backspace') {
        const activeElement = document.activeElement;
        const isFormElement = activeElement && (
          activeElement.tagName === 'INPUT' ||
          activeElement.tagName === 'TEXTAREA' ||
          activeElement.tagName === 'SELECT' ||
          activeElement.tagName === 'DIV'
        );

        if (!isFormElement) {
          event.preventDefault();
          history.goBack();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [history]);

  useEffect(() => {
    (async () => {
      if (currentUser.authenticated) {
        runGlobalTokenChecker();
        const {
          email,
          firstName,
          lastName,
          intercomUserHash,
          userId,
          language,
          createdAt,
          companyName,
          companyWebsite,
          permissionLevel,
          clientId,
        } = currentUser;

        boot({
          email,
          name: `${firstName} ${lastName}`,
          userHash: intercomUserHash,
          company: {
            companyId: clientId.toString(),
            name: companyName,
            website: companyWebsite,
          },
          customAttributes: {
            createdAt,
            permissionLevel: PermissionLevel[permissionLevel],
            status: 'active',
            userId,
            clientId,
            languageOverride: language || null,
          },
        });
      } else {
        stopGlobalTokenChecker();
        shutdown();
      }
    })();
  }, [currentUser, boot, shutdown]);

  const setClientDetails = async (clientId, superAdmin = null) => {
    await dispatchToolkit(
      getClient({
        superAdmin,
        id: clientId,
      })
    );

    if (superAdmin) {
      await dispatchToolkit(getClientSettings());
    }

    await dispatchToolkit(getAllSettings(undefined));
  };

  useEffect(() => {
    if (currentUser.authenticated) {
      setClientDetails(get(currentUser, 'clientId'), get(currentUser, 'superAdmin'));
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser.authenticated && !isSettingsLoading) {
      (async () => {
        if (
          search.location &&
          activeClientConfig &&
          currentUser.clientId &&
          moment() < moment(localStorage.getItem(tokenExpiration))
        ) {
          history.push(search.location as string);
        } else if (currentUser.clientId && location.pathname === '') {
          history.push('/positions');
        } else if (currentUser.clientId && location.pathname.includes('change-password')) {
          await dispatchToolkit(logout());
          history.push(`/change-password${location.search}`);
        } else if (!currentUser.clientId && !location.pathname.includes(selectClientPath)) {
          history.push(selectClientPath);
        }
      })();
    }
  }, [currentUser, location, isSettingsLoading]);

  useEffect(() => {
    if (localStorage['ts-login-date']) {
      const lastLoginDate = new Date(localStorage['ts-login-date']);
      const todaysDate = new Date();

      if (lastLoginDate.getDay() !== todaysDate.getDay()) {
        dispatchToolkit(logout());
      }
    }
  }, []);

  // SSO
  useEffect(() => {
    (async () => {
      if (location) {
        const { search } = location;
        const params = new URLSearchParams(search);
        const accessToken = params.get('access_token');
        const refreshToken = params.get('refresh_token');
        const issuer = params.get('issuer');

        if (accessToken && refreshToken && issuer) {
          try {
            toggleSplash(true);
            // TODO: Auth0 was following idToken authorization hence we need to change it eventually
            dispatchToolkit(setTokens({ accessToken, refreshToken, idToken: accessToken }));

            setDataInLocalStorage({
              id_token: accessToken,
              refresh_token: refreshToken,
              issuer,
            });

            const user = await dispatchToolkit(getUser()).unwrap();

            if (user) {
              if (user.clientId !== 0) {
                await dispatchToolkit(getAllSettings(undefined));
              }
            }
          } catch (error) {
            dispatchToolkit(logout());
            log(error);
          } finally {
            toggleSplash(false);
            params.delete('access_token');
            params.delete('refresh_token');
            params.delete('issuer');
          }
        }
      }
    })();
  }, [location]);

  const LoggedInMenu = useMemo(
    () =>
      LoggedInUserMenu.filter(({ permission, showFn }) =>
        isPermitted(permission, currentUser, showFn, activeClientConfig)
      ),
    [LoggedInUserMenu, currentUser]
  );

  const getCurrentUser = async () => {
    try {
      await dispatchToolkit(getUser());
    } catch (error) {
      log(error);
    }
  };

  const [isRendered, setIsRendered] = useState(false);
  const tokenChecker = async (fetchCurrentUser = false) => {
    if (
      localStorage.getItem('ts-api-token') &&
      localStorage.getItem(tokenExpiration) &&
      new Date() > new Date(localStorage.getItem(tokenExpiration))
    ) {
      if (!isTokenRefreshing) {
        await dispatchToolkit(refreshToken());
      }
    } else if (currentUser.authenticated && fetchCurrentUser) {
      await getCurrentUser();
    }

    if (!isRendered) {
      setIsRendered(true);
    }
  };

  useEffect(() => {
    tokenChecker(!isRendered);
  }, [get(location, 'pathname'), isRendered]);

  useEffect(() => {
    events.$on(
      'switch-client',
      ({ clientId }) => {
        setClientDetails(clientId);
      },
      []
    );

    return () => {
      events.$off('switch-client', []);
    };
  }, []);

  return (
    <>
      <Suspense
        fallback={
          <div className="full-size">
            <div className="lds-ripple">
              <div />
              <div />
            </div>
          </div>
        }
      >
        {!currentUser.authenticated && (
          <Switch>
            {UnauthorizeRoutes.map(({ path, component: Component }) => (
              <Route key={path} exact path={path} render={() => <Component />} />
            ))}
            <Route component={NotFound} />
          </Switch>
        )}
        {currentUser.authenticated && !isSettingsLoading && (
          <>
            <ModalContainer />
            <div className="default-layout">
              {location.pathname && !location.pathname.startsWith(selectClientPath) && (
                <Sidebar isCareerPageActive={shouldTopbarHidden} />
              )}
              <div
                className={classnames('right-content relative', {
                  'right-content--minimized': minimized || shouldTopbarHidden,
                })}
              >
                {!shouldTopbarHidden && <Topbar />}
                {!isTokenExpired && (
                  <Switch>
                    {LoggedInMenu.map(({ path, component }) => (
                      <PrivateRoute key={path} exact path={path} component={component} isTokenExpired />
                    ))}
                    <Route component={NotFound} />
                  </Switch>
                )}
                {isTokenExpired && (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      marginTop: 70,
                      height: '100%',
                    }}
                  >
                    {/* TODO: migrate Loader component to typescript */}
                    {/* @ts-ignore */}
                    <Loader type="primary" size={60} />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </Suspense>
    </>
  );
};

export default routes;
