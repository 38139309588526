import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';
import useElementClickHandlers from '../../../hooks/useElementClickHandlers';
import Filter from '../../../components/@v2/Filter/Filter';
import SelectFilter from '../../../components/@v2/Filter/SelectFilter';
import ButtonFilter from '../../../components/@v2/Button/ButtonFilter';
import Button from '../../../components/Button';
import useFilterModal from '../../../hooks/useFilterModal';
import Icon from '../../../components/@v2/Icon';
import { useSelector as useSelectorToolkit } from '../../../store';
import { selectUser } from '../../../store/selectors/user.selector';
import { departmentLookup } from '../../../store/api/departments.api';
import { getAllLocations } from '../../../store/api/location.api';

const PositionFilter = () => {
  const { t } = useTranslation();
  const [isFilterShown, setIsFilterShown] = useState(false);
  const { meta, setMeta } = useApiRequestContext();
  const currentUser = useSelectorToolkit(selectUser);
  const options = useSelectorToolkit(({ settings }) => settings.options);
  const history = useHistory();

  useElementClickHandlers({
    condition: isFilterShown,
    callback: setIsFilterShown,
    elements: ['.ts-filter-button', '.ts-filter', '.rc-dropdown', '.searchable-select'],
  });

  useFilterModal({
    show: isFilterShown,
  });

  const changeMeta = (key, value, label) => {
    const currentFilters = [...meta.filters].filter((item) => {
      return item.key !== key;
    });

    const newFilters =
      value === '' || !!value ? [...currentFilters, { key, value, label: label || null }] : currentFilters;

    setMeta({
      ...meta,
      filters: newFilters,
      page: 1,
    });

    localStorage.setItem('positionFilters', JSON.stringify(newFilters));
    setIsFilterShown(false);
  };

  const getValue = (filterKey, bypass, arr, arrKey) => {
    const metaFiltersSelected = meta?.filters?.find(({ key }) => key === filterKey);

    if (bypass) {
      if (metaFiltersSelected?.label && metaFiltersSelected?.value) {
        return {
          label: metaFiltersSelected?.label,
          value: metaFiltersSelected?.value,
        };
      }

      return null;
    }

    let filterValue = null;

    if (metaFiltersSelected) {
      filterValue = arr.find((item) => item[arrKey] === metaFiltersSelected.value);
    }
    return filterValue;
  };

  const recruiterValue = getValue('recruiterUserId', true);
  const departmentValue = getValue('departmentId', true);
  const locationValue = getValue('locationId', true);
  const onRecruiterSelect = (selected) => {
    changeMeta('recruiterUserId', selected?.id || null, selected?.name || null);
  };

  const onLocationSelect = (selected) => {
    changeMeta('locationId', selected?.id || null, selected?.name || null);
  };

  const onDepartmentSelect = (selected) => {
    changeMeta('departmentId', selected?.value || selected?.id || null, selected?.label || selected?.name || null);
  };

  const clearFilters = () => {
    setMeta({
      ...meta,
      filters: [],
      page: 1,
    });
    localStorage.removeItem('positionFilters');

    // Remove 'tab' parameter from the URL
    history.push({
      pathname: location.pathname,
      search: '',
    });
  };

  return (
    <>
      <ButtonFilter isShown={isFilterShown} callback={setIsFilterShown} filters={get(meta, 'filters') || []} />
      {createPortal(
        <Filter show={isFilterShown}>
          <div className="flex justify-end">
            <button
              data-testid="filter-modal-close-button"
              type="button"
              onClick={() => {
                setIsFilterShown(false);
              }}
            >
              <Icon name="times" />
            </button>
          </div>
          <div className="position-filters">
            {currentUser.permissionLevel !== 0 && (
              <SelectFilter
                searchable
                id="position-filter--recruiter"
                label={t('positions.table-header.recruiter')}
                options={options?.recruiters || []}
                callback={onRecruiterSelect}
                defaultFilters="visible==true"
                searchKey="name"
                keys={['id', 'name']}
                value={
                  recruiterValue
                    ? {
                        id: recruiterValue?.value,
                        name: recruiterValue?.label,
                      }
                    : null
                }
                buttonProps={{
                  'data-testid': 'filter-0-select',
                }}
              />
            )}
            <SelectFilter
              id="position-filter--departments"
              label={t('general.departments')}
              value={
                departmentValue
                  ? {
                      id: departmentValue?.value,
                      name: departmentValue?.label,
                    }
                  : null
              }
              api={departmentLookup}
              keys={['id', 'name']}
              searchKey="name"
              callback={onDepartmentSelect}
              buttonProps={{
                'data-testid': 'filter-2-select',
              }}
            />
            <SelectFilter
              id="position-filter--location"
              label={t('general.location')}
              value={
                locationValue
                  ? {
                      id: locationValue?.value,
                      name: locationValue?.label,
                    }
                  : null
              }
              api={getAllLocations}
              keys={['id', 'name']}
              searchKey="name"
              callback={onLocationSelect}
              buttonProps={{
                'data-testid': 'filter-2-select',
              }}
            />
          </div>
          {meta.filters.length > 0 && (
            <Button className="btn-rounded w-100" color="primary" onClick={clearFilters}>
              {t('general.reset-filter')}
            </Button>
          )}
        </Filter>,
        document.body
      )}
    </>
  );
};

export default PositionFilter;
