import React, { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { get, isNumber, omit, sortBy, uniqueId } from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import Table from '../../../components/@v2/Table/Table';
import Card from '../../../components/@v2/Card';
import ApiRequestProvider, { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';
import Paginator from '../../../components/@v2/Paginator/Paginator';
import Search from '../../../components/@v2/Search/Search';
import Layout from '../../../components/layouts/default';
import Icon from '../../../components/@v2/Icon';
import PositionFilter from './PositionFilter';
import Avatar from '../../../components/Avatar';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../../store';
import { setActivePosition, setCandidatesWithKey, setFiltersWithKey } from '../../../store/slices/settings.slice';
import events from '../../../helpers/events';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { getImageServiceMediaUrl } from '../../../helpers/image';
import MoreActionButton from '../../../components/@v2/PositionActionButtons/MoreActionButton';
import CopyActionButton from '../../../components/@v2/PositionActionButtons/CopyActionButton';
import { format, isAfter } from '../../../helpers/date';
import PositionSort from './PositionSort';
import DynamicPositionColumnName from './PositionName';
import PositionLocation from './PositionLocation';
import { getPositionCount, getPositionListV2, getPipelineSteps } from '../../../store/api/positions.api';
import Button from '../../../components/Button';
import Badge from '../../../components/@v2/Badge';

const tabKeys = ['all-positions', 'active', 'close', 'scheduled', 'drafts', 'archived'];

const PositionColumnWrapper = ({ children, onClick, disabled, index, columnIndex, ...rest }) => {
  return (
    <td
      className="cursor-pointer"
      onClick={onClick}
      disabled={disabled}
      data-testid={`position-${index}-${columnIndex}`}
      {...rest}
    >
      {children}
    </td>
  );
};

export const getFirstPipelineStep = (pipelines) => {
  if (pipelines && pipelines?.length) {
    const findItemWithCandidates = sortBy(pipelines, 'sortOrder').find((item) => item.candidates >= 1);
    if (findItemWithCandidates) {
      return findItemWithCandidates;
    }
  }

  return null;
};

const Positions = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const apiRequestContext = useApiRequestContext();
  const { loading, meta, setMeta, reloadData } = apiRequestContext;
  const data = apiRequestContext?.data?.data;
  const counts = apiRequestContext?.data?.counts;
  const publicUrl = global?.appConfig?.public_url || '';
  const [rowLoadingId, setRowLoadingId] = useState(null);

  const getPositionAndCandidatesDetails = async (id, positionItem) => {
    if (rowLoadingId) return;

    try {
      setRowLoadingId(id);

      dispatchToolkit(
        setCandidatesWithKey({
          key: 'selectAll',
          payload: false,
        })
      );

      dispatchToolkit(
        setCandidatesWithKey({
          key: 'unchecked',
          payload: [],
        })
      );

      dispatchToolkit(
        setCandidatesWithKey({
          key: 'selected',
          payload: [],
        })
      );

      dispatchToolkit(
        setCandidatesWithKey({
          key: 'selectAll',
          payload: false,
        })
      );

      dispatchToolkit(
        setCandidatesWithKey({
          key: 'unchecked',
          payload: [],
        })
      );

      dispatchToolkit(
        setCandidatesWithKey({
          key: 'selected',
          payload: [],
        })
      );

      if (positionItem) {
        dispatchToolkit(
          setActivePosition({
            ...positionItem,
          })
        );
        // change once a new endpoint is made for getting pipeline steps only
        const pipelines = await getPipelineSteps(positionItem.id || positionItem.positionId);

        if (pipelines) {
          dispatchToolkit(
            setFiltersWithKey({
              key: 'candidates',
              payload: {
                positionId: positionItem.id || positionItem.positionId,
                status: 'Active',
                rejected: false,
                pipelineStepId: pipelines && pipelines.length ? pipelines[0]?.id : null,
              },
            })
          );

          history.push('/candidates');
        }
      }
    } catch (error) {
      setRowLoadingId(null);
    }
  };

  useEffect(() => {
    events.$on('refresh-table--positions', () => {
      reloadData();
    });

    return () => {
      events.$off('refresh-table--positions');
    };
  }, [events, apiRequestContext]);

  const teamMembersRef = useRef([]);
  const pageSize = useSelectorToolkit(({ settings }) => get(settings, 'table.pageSize'));

  if (teamMembersRef.current?.length !== pageSize) {
    teamMembersRef.current = Array(pageSize)
      .fill()
      .map((_, i) => teamMembersRef.current[i] || createRef());
  }

  const windowDimensions = useWindowDimensions();

  const onMouseEnter = () => {
    setTimeout(() => {
      const tooltipSelector = document?.body?.querySelector('.position-team-member-tooltip');
      const tooltipSelectorDiv = document?.body?.querySelector('.position-team-member-tooltip > div');

      const offsetTop = tooltipSelector?.getBoundingClientRect().top;
      const defaultYSpacing = 50;
      const multiplier = 2;

      if (offsetTop < 0) {
        tooltipSelector.style.transform = `translateY(${Math.abs(offsetTop) + defaultYSpacing}px)`;
      }

      if (tooltipSelectorDiv?.querySelector('.max-height-fill')) {
        tooltipSelectorDiv.querySelector('.max-height-fill').style.maxHeight =
          windowDimensions.height - offsetTop - defaultYSpacing > windowDimensions.height
            ? `${windowDimensions.height - defaultYSpacing * multiplier}px`
            : `${windowDimensions.height - offsetTop - defaultYSpacing}px`;
      }
    }, 350);
  };

  const onEditPosition = (id) => {
    history.push(`/positions/edit/${id}`);
  };

  const location = useLocation();

  const onEditPositionPromoteStep = (id) => {
    history.push(`/positions/edit/${id}`, {
      component: 'promote',
      fromOrder: 4,
    });
  };

  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    {
      key: 'all-positions',
      label: t('candidates.filter.all-positions'),
      value: [{ key: 'statuses', value: [0, 1, 2, 3] }],
      countKey: 'allCount',
    },
    {
      key: 'active',
      label: t('position.open'),
      value: [{ key: 'status', value: '0' }],
      countKey: 'openCount',
    },
    {
      key: 'close',
      label: t('position.closed'),
      value: [{ key: 'status', value: '1' }],
      countKey: 'closedCount',
    },
    {
      key: 'scheduled',
      label: t('position.scheduled'),
      value: [{ key: 'status', value: '2' }],
      countKey: 'scheduledCount',
    },
    {
      key: 'drafts',
      label: t('position.drafts'),
      value: [{ key: 'status', value: '3' }],
      countKey: 'draftCount',
    },
    {
      key: 'archived',
      label: t('position.archived'),
      value: [{ key: 'status', value: '4' }],
      countKey: 'archivedCount',
    },
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabKey = queryParams.get('tab');
    const tabIndex = tabs.findIndex((tab) => tab.key === tabKey);
    const savedFilters = JSON.parse(localStorage.getItem('positionFilters')) || [];

    if (tabIndex !== -1) {
      setActiveTab(tabIndex);
      setTimeout(() => {
        setMeta({
          ...meta,
          filters: [...savedFilters, ...tabs[tabIndex].value],
          page: 1,
          sorts: tabIndex === 0 ? '' : 'applicationDeadline',
        });
      }, 0);
    }
  }, [location.search]);

  return (
    <Layout
      hasTabs
      title={t('general.positions')}
      testId="position"
      rightContent={
        <Button
          className="btn-rounded"
          color="primary"
          data-testid="position-create-button"
          onClick={() => {
            history.push('template-selector');
          }}
          id="template-selector"
        >
          <div className="flex items-center gap-x-1">
            <Icon name="plus" />
            {t('general.new-position')}
          </div>
        </Button>
      }
    >
      <Nav tabs className="!mb-6">
        {tabs.map((item, index) => (
          <NavItem key={JSON.stringify(item)}>
            <NavLink
              className={classNames(
                'transition !px-4 group flex items-center justify-center',
                index === activeTab ? 'active font-bold' : '!text-secondary'
              )}
              data-testid={`tab-${item.label.split(' ').join('-').toLowerCase()}`}
              onClick={() => {
                if (index === activeTab) {
                  return;
                }

                setActiveTab(index);
                setMeta({
                  ...meta,
                  filters: [...meta.filters.filter((obj) => obj.key !== 'status'), ...item.value],
                  page: 1,
                  sorts: index === 0 ? '' : 'applicationDeadline',
                });
                // Update the URL with the selected tab
                history.push({
                  pathname: location.pathname,
                  search: `?tab=${tabs[index].key}`,
                });
              }}
            >
              <span className="transition leading-none group-hover:text-black">{item.label}</span>
              {isNumber(counts?.[item?.countKey]) && (
                <Badge type={index === activeTab ? 'primary' : 'default'} className="relative -top-0.5 !ml-2">
                  {counts?.[item?.countKey] || 0}
                </Badge>
              )}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <Card className="anim-table-delayed shadow-table !border !border-line-default">
        <Search
          placeholder={t('general.search')}
          resource="name"
          meta={meta}
          setMeta={setMeta}
          filter={<PositionFilter />}
          sort={<PositionSort activeTab={activeTab} />}
        />
        <Table
          isLoaded={!loading}
          items={data?.items || []}
          pageSize={meta.pageSize}
          activeSort={meta.sorts}
          headers={[
            { label: '', sort: false, key: 'icons', width: 59 },
            {
              label: 'general.position',
              key: 'name',
            },
            {
              label: 'general.location',
              key: 'Location',
            },
            {
              label: 'general.recruiter',
              key: 'recruiter',
            },
            {
              label: 'general.applications',
              key: 'applications',
            },
            {
              label: 'general.unhandled',
              key: 'unhandledApplications',
            },
            {
              sticky: true,
              sort: false,
              label: 'general.actions',
              width: 136,
              headerClassName: 'text-center',
            },
          ]}
          setMeta={setMeta}
          renderBody={(positionItem, index) => {
            const {
              id,
              name: position,
              locationName: location,
              recruiter,
              candidatesCount: candidates,
              unhandledCandidatesCount: unhandledApplications,
              alternativeApplyFormLink,
              publicLink,
              publicApplyFormLink,
              useAlternativeApplyForm,
              draft,
              applicationDeadline,
              archived,
              teamMembers,
              isHiddenOnCareerPage,
              publishDate,
              status,
            } = positionItem;
            const { avatar } = recruiter;

            const currentDate = format('date', new Date());
            const formattedScheduledDate = publishDate && format('date', publishDate);
            const formattedApplicationDeadline = applicationDeadline && format('date', applicationDeadline);

            const scheduled = publishDate && isAfter(formattedScheduledDate, currentDate);

            const getDateLabel = () => {
              return scheduled ? formattedScheduledDate : formattedApplicationDeadline;
            };

            const getStatusLabel = () => {
              if (status === 4) {
                return t('position.archived');
              }

              if (status === 3) {
                return t('position.draft');
              }

              if (status === 2) {
                return t('position.scheduled');
              }

              if (status === 0) {
                return t('position.open');
              }

              return t('position.closed');
            };

            const positionNameElem = <DynamicPositionColumnName position={position} isLoading={rowLoadingId === id} />;

            return (
              <tr key={`position-${id}`}>
                <PositionColumnWrapper
                  onClick={() => {
                    getPositionAndCandidatesDetails(id, positionItem);
                  }}
                  disabled={!!rowLoadingId}
                  index={index}
                  columnIndex={0}
                >
                  {isHiddenOnCareerPage && (
                    <Tippy
                      content={t('position.hidden-on-career-page')}
                      maxWidth={1000}
                      placement="bottom-start"
                      key={`position-tippy-hidden-${id}` || uniqueId()}
                      theme="light"
                    >
                      <div>
                        <Icon name="eye-slash" />
                      </div>
                    </Tippy>
                  )}
                </PositionColumnWrapper>
                <PositionColumnWrapper
                  onClick={() => {
                    getPositionAndCandidatesDetails(id, positionItem);
                  }}
                  disabled={!!rowLoadingId}
                  index={index}
                  width={200}
                  columnIndex={1}
                >
                  <div className="flex flex-col">
                    {positionNameElem}
                    {!draft ? (
                      <Tippy
                        content={
                          <div className="flex flex-col text-xs !p-2 gap-y-1.5">
                            <p className="mb-0">
                              {scheduled ? t('pages.publishes') : t('pages.published')}:{' '}
                              <span className="font-bold">{formattedScheduledDate}</span>
                            </p>
                            {formattedApplicationDeadline && (
                              <p className="mb-0">
                                {t('general.application-deadline')}:{' '}
                                <span className="font-bold">{formattedApplicationDeadline}</span>
                              </p>
                            )}
                          </div>
                        }
                        placement="bottom-start"
                        key={`position-tippy-team-members-${id}` || uniqueId()}
                        theme="light"
                      >
                        <div className="inline-flex items-center gap-x-1 !text-secondary">
                          <div
                            className={classNames(
                              'w-[8px] h-[8px] rounded-full',
                              status === 0 && '!bg-success',
                              status === 1 && '!bg-error',
                              status === 2 && 'border-2 !border-success !bg-transparent',
                              status === 3 && '!bg-medium-gray',
                              status === 4 && 'border-2 !border-medium-gray !bg-transparent'
                            )}
                          />
                          <p className="mb-0">{getStatusLabel()}</p>
                          {!draft && formattedApplicationDeadline && (
                            <>
                              <span>-</span>
                              <span>{getDateLabel()}</span>
                            </>
                          )}
                        </div>
                      </Tippy>
                    ) : (
                      <div className="inline-flex items-center gap-x-1 !text-secondary">
                        <div
                          className={classNames(
                            'w-[8px] h-[8px] rounded-full',
                            archived ? '!bg-transparent border-2 border-gray-300' : '!bg-gray-300'
                          )}
                        />
                        <p className="mb-0">{getStatusLabel()}</p>
                      </div>
                    )}
                  </div>
                </PositionColumnWrapper>
                <PositionColumnWrapper
                  onClick={() => {
                    getPositionAndCandidatesDetails(id, positionItem);
                  }}
                  disabled={!!rowLoadingId}
                  index={index}
                  columnIndex={2}
                >
                  <PositionLocation location={location} id={id} />
                </PositionColumnWrapper>
                <PositionColumnWrapper
                  onClick={() => {
                    getPositionAndCandidatesDetails(id, positionItem);
                  }}
                  disabled={!!rowLoadingId}
                  index={index}
                  columnIndex={3}
                >
                  {teamMembers?.length ? (
                    <Tippy
                      content={
                        <div className="px-2 pt-2 overflow-y-auto">
                          <p className="mb-2.5 text-xs uppercase tracking-wide text-left opacity-60">
                            {t(teamMembers?.length > 1 ? 'positions.team-members' : 'positions.team-member')} (
                            {teamMembers?.length})
                          </p>
                          <div className="max-height-fill">
                            {teamMembers.map((teamMember) => (
                              <div className="flex border-t gap-x-3 border-black border-opacity-5 !py-2.5 overflow-hidden">
                                <Avatar
                                  src={getImageServiceMediaUrl({
                                    ...teamMember?.avatar,
                                    name: teamMember?.avatar?.id,
                                  })}
                                  size={30}
                                />
                                <div className="text-left">
                                  {(teamMember?.firstName || teamMember?.lastName) && (
                                    <p className="font-bold text-sm mb-0">
                                      {teamMember?.firstName} {teamMember?.lastName}
                                    </p>
                                  )}
                                  {teamMember?.email && <p className="opacity-40 mb-0">{teamMember?.email}</p>}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      }
                      maxWidth={1000}
                      placement="left"
                      key={`position-tippy-team-members-${id}` || uniqueId()}
                      theme="light"
                    >
                      <div
                        id={`positions-td-${index}-2`}
                        ref={teamMembersRef.current[index]}
                        onMouseEnter={() => {
                          if (teamMembers?.length) {
                            onMouseEnter();
                          }
                        }}
                      >
                        <div className="flex items-center" id={`team-member-${index}`}>
                          <Avatar
                            src={
                              getImageServiceMediaUrl({
                                ...avatar,
                                name: avatar?.id,
                              }) || `${publicUrl}/images/avatars/default-avatar.svg`
                            }
                            size={30}
                          />
                          <span className="whitespace-nowrap ml-2">{recruiter?.name}</span>
                          {!!teamMembers?.length && (
                            <span className="ml-2 bg-primary text-tiny mr-2.5 mb-0 px-2 leading-4 rounded-md flex items-center">
                              {teamMembers?.length}
                            </span>
                          )}
                        </div>
                      </div>
                    </Tippy>
                  ) : (
                    <div className="flex items-center" id={`team-member-${index}`}>
                      <Avatar
                        src={
                          getImageServiceMediaUrl({
                            ...avatar,
                            name: avatar?.id,
                          }) || `${publicUrl}/images/avatars/default-avatar.svg`
                        }
                        size={30}
                      />
                      <span className="whitespace-nowrap ml-2">{recruiter?.name}</span>
                    </div>
                  )}
                </PositionColumnWrapper>
                <PositionColumnWrapper
                  onClick={() => {
                    getPositionAndCandidatesDetails(id, positionItem);
                  }}
                  disabled={!!rowLoadingId}
                  index={index}
                  columnIndex={4}
                >
                  {candidates || '-'}
                </PositionColumnWrapper>
                <PositionColumnWrapper
                  onClick={() => {
                    getPositionAndCandidatesDetails(id, positionItem);
                  }}
                  disabled={!!rowLoadingId}
                  index={index}
                  columnIndex={5}
                >
                  {unhandledApplications || '-'}
                </PositionColumnWrapper>
                <td data-testid={`positions-td-${index}-6`} className="column-sticky">
                  <div className="flex items-center justify-center gap-x-3">
                    <Tippy
                      content={t('general.edit-position')}
                      placement="top"
                      key={`position-tippy-team-members-${id}` || uniqueId()}
                      theme="light"
                    >
                      <button
                        type="button"
                        data-testid={`position-${index}-edit`}
                        onClick={() => {
                          onEditPosition(id);
                        }}
                      >
                        <Icon name="pencil" />
                      </button>
                    </Tippy>
                    <Tippy content={t('general.copy')} placement="top" theme="light">
                      <div>
                        <CopyActionButton
                          id={id}
                          index={index}
                          alternativeApplyFormLink={alternativeApplyFormLink}
                          publicLink={publicLink}
                          publicApplyFormLink={publicApplyFormLink}
                          useAlternativeApplyForm={useAlternativeApplyForm}
                        />
                      </div>
                    </Tippy>
                    <Tippy content={t('general.promote')} placement="top" theme="light">
                      <button
                        type="button"
                        onClick={() => {
                          onEditPositionPromoteStep(id);
                        }}
                      >
                        <Icon name="megaphone" />
                      </button>
                    </Tippy>
                    <Tippy content={t('general.more-actions')} placement="top" theme="light">
                      <div>
                        <MoreActionButton
                          id={id}
                          name={position}
                          archived={activeTab === tabs.findIndex((item) => item.key === 'archived')}
                          data={data?.items || []}
                          index={index}
                        />
                      </div>
                    </Tippy>
                  </div>
                </td>
              </tr>
            );
          }}
        />
        <div className="h-14 flex items-center bg-white">
          <Paginator
            pageCount={data?.totalCount / meta?.pageSize}
            initialPage={meta.page - 1}
            onPageChange={(value) => {
              if (meta.page !== value) {
                setMeta({
                  page: value,
                });
              }
            }}
            disableInitialCallback
          />
        </div>
      </Card>
    </Layout>
  );
};

const PositionsWrapper = () => {
  return useMemo(
    () => (
      <ApiRequestProvider
        withCancellation
        api={async (meta, source, ...rest) => {
          const newFilters = {};

          const savedFilters = JSON.parse(localStorage.getItem('positionFilters'));
          const filters = [...rest?.[2], ...savedFilters] || [];

          // Workaround to prevent race condition when initializing the web page
          const queryParams = new URLSearchParams(location.search);
          const tabKey = queryParams.get('tab');
          const tabIndex = tabKeys.findIndex((tab) => tab === tabKey);
          if (tabIndex !== -1 && filters.findIndex((item) => item.key === 'status') === -1) {
            filters.push(...tabs[tabIndex].value);
          }

          filters.forEach((item) => {
            newFilters[item.key] = item.key === 'status' ? parseInt(item.value, 10) : item.value;
          });

          const sort = {
            by: meta?.sorts?.replace('-', ''),
            direction: meta.sorts.includes('-') ? 'desc' : 'asc',
          };

          const data = await getPositionListV2({
            filter: newFilters,
            page: {
              number: meta.page,
              size: meta.pageSize,
            },
            sort: meta.sorts ? sort : {},
          });

          const counts = await getPositionCount(
            omit(newFilters, ['status'], {
              cancelToken: source.token,
            })
          );

          return {
            data,
            counts: {
              ...counts,
              allCount: counts.closedCount + counts.openCount + counts.scheduledCount + counts.draftCount,
            },
          };
        }}
      >
        <Positions />
      </ApiRequestProvider>
    ),
    []
  );
};

export default PositionsWrapper;
